.comment {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    /* border-top: 1px #f1f1f1 solid; */
}


.comment .comment-box {
    flex-direction: column;
    width: 93%;
    margin-right: 5px;
    margin-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    padding-right: 10px;
    padding-left: 10px;
}


.comment .comment-box .comment-text .user-name {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
}

.comment .comment-box .comment-text .user  {
    display: flex;
    justify-content: space-between;
}

.comment .comment-box .comment-text {
    width: 98%;
    background-color: #f1f1f1;
    border-radius: 10px;
    margin-left: 5px;
    padding-top: 5px;
    /* padding-bottom: 1px; */
    padding-right: 10px;
    padding-left: 10px;
}

.comment .comment-box .comment-text .user-box {
    display: flex;
    justify-content: space-between;
}

.comment .comment-box .comment-text .user-box .name {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
}

.comment .comment-box .comment-text .user-box .timestamp {
    font-size: 12px;
    font-weight: 600;
    margin: 0px;
}

.comment .comment-box .comment-text .text {
    font-size: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0px;
}

.comment .comment-box .buttons-box {
    display: flex;
    align-items: center;
}

.comment .comment-box .buttons-box .comment-button {
    font-weight: 700;
    margin-right: 10px;
    color: #808080;
    font-size: 12px !important;
}

.comment-input-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    /* border-top: 1px #f1f1f1 solid; */
}

.comment-input-component input {
    width: 83%;
    border-radius: 30px;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f0f0f0;
    outline: none;
}

.emoji-picker {
    position:relative;
    display: flex;
    justify-content: center;
}
