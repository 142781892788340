.page {
    height: 100vh;
    background-color: white;
}

.container {
  padding-top: 50px;
}


.separator {
    width: 1.5px;
    height: 30px;
    background-color: '#424242';
    margin-left: 'auto';
    margin-right: 'auto';
}

.userContainer {
    display: 'flex';
    justify-content: center;
    margin-right: 10px;
}

.subordinateContainer {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    /* padding-top: 15px; */
    padding-bottom: 15px;
    /* border-top: 1.5px solid #424242; */
    padding-right: 5px
}

.itemContainer {
    width: 100%;
    height: 130px;
    padding: 10px;
    border: 1px solid #808080;
    border-radius: 3px;
    margin-left: 5px;
    margin-left: 5px;
}

.image {
    width: 50px;
    /* height: 50px; */
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #424242;
}

.name {
    margin-top: 10px;
    font-size: 13px;
    text-align: center;
    font-weight: bold
}

.username {
    font-size: 11px;
    color: #808080;
    text-align: center;
}