.container {
    background-color: white;
}

.containerDescription {
    margin-bottom: 15px;
}

.title {
    font-weight: 600;
    margin-top: 15px
}

.description {
    cursor: pointer;
    margin-bottom: 2px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.date {
    cursor: pointer;
    margin: 0px;
    font-size: 15px;
    margin-bottom: 3px;
}

.participateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #C0C0C0;
    border-radius: 3px;
}

.participateTitle {
    font-weight: 600;
    margin: 0px;
}

.participateDescription {
    margin: 0px;
}