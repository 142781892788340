.create-opinion {
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

.create-opinion .create-opinion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-bottom: 1px #f1f1f1 solid
}

.create-opinion .create-opinion-content {
    padding-left: 3%;
    padding-right: 3%;
}

.create-opinion .create-opinion-content .box-user {
    align-items: center;
    margin-bottom: 10px;
}

.create-opinion .create-opinion-content .box-user .select-diffusion {
    font-size: 12px;
    padding: 2px;
    border: '1px #f1f1f1 solid'
}

.create-opinion .create-opinion-content .box-user .select-diffusion-item {
    font-size: 12px;
    padding: 2px;
}

.create-opinion .create-opinion-content .mention-input {
    min-height: 100px;
    margin-bottom: 20px;
}



.create-opinion .create-opinion-content .box-attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px #C0C0C0 solid;
    border-radius: 5px;
}

.create-opinion .create-opinion-content .button-create {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 0px
}

.create-opinion .create-opinion-content .button-create .button-create-text {
    color: white;
    font-weight: '500';
    font-size: '12px';
    margin-top: 20px;
}